<template>

  <div>
    <RoleAddNew
      :is-add-new-role-sidebar-active.sync="isAddNewRoleSideBar"
      @refetch-data="refreshTable"
    />
    <RoleUpdate
      :is-update-role-sidebar-active.sync="isUpdateRoleSidebarActive"
      :role-data="dataRole"
      @refetch-data="refreshTable"
    />
    <tracking-filter
      :date.sync="dateFilter"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                class="mr-1"
              >
                <export-excel
                  :data="exportData"
                  :fields="dataFieldsDefine"
                  :name="`RatingUser_${moment().format('HH:mm:ss_DD/MM/YYYY')}.xls`"
                >
                  Export data
                </export-excel>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refRoleManagerTable"
          class="position-relative"
          :items="fetchRoleManager"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          @row-clicked="handleViewProducts"
        >
          <template
            slot="row-details"
            slot-scope="scope"
          >
            <div
              v-for="(rating, index) in scope.item.ratings"
              :key="rating.id"
              class="d-flex items-center"
              :class="index < scope.item.ratings.length - 1 ? 'mb-1' : ''"
            >
              <div class="d-flex">
                <div
                  class="text-primary font-weight-bold"
                >{{ rating.ratingRef.title }}:</div>
                <div class="ml-1">
                  <span>{{ rating.rating }}</span>
                </div>
              </div>
            </div>
          </template>
          <!-- Column: surname -->
          <template #cell(resNo)="data">
            <span v-if="data.item.currentCustomer">
              {{ data.item.currentCustomer.resCode }}
            </span>
          </template>
          <!-- Column: surname -->
          <template #cell(surName)="data">
            <span v-if="data.item.currentCustomer">
              {{ data.item.currentCustomer.surname }}
            </span>
          </template>
          <!-- Column: surname -->
          <template #cell(givenName)="data">
            <span v-if="data.item.currentCustomer">
              {{ data.item.currentCustomer.guestGiven }}
            </span>
          </template>
          <!-- Column: arrivalDate -->
          <template #cell(arrivalDate)="data">
            <span v-if="data.item.currentCustomer">
              {{ formatDate(data.item.currentCustomer.arrivalDate) }}
            </span>
          </template>
          <!-- Column: departureDate -->
          <template #cell(departureDate)="data">
            <span v-if="data.item.currentCustomer">
              {{ formatDate(data.item.currentCustomer.departureDate) }}
            </span>
          </template>
          <!-- Column: room -->
          <template #cell(room)="data">
            <span v-if="data.item.currentCustomer">
              {{ data.item.currentCustomer.room }}
            </span>
          </template>
          <!-- Column: ratingRef -->
          <template #cell(ratingTitle)="data">
            <span v-if="data.item.customer">
              {{ data.item.ratingRef.title }}
            </span>
          </template>
          <!-- Column: createdAt -->
          <template #cell(createdAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <!-- Column: updatedAt -->
          <template #cell(updatedAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.updatedAt) }}
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              :to="{name: 'edit-role', params:{id: data.item.id}}"
            >
              Edit
            </b-link>
            <b-link
              @click="deleteRate(data.item.id)"
            >
              Delete
            </b-link>
          </template>

        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BPagination, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import roleManager from '@/views/userRating/ratingUser'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { mapGetters } from 'vuex'
import RoleAddNew from '@/components/roleManager/RoleAddNew.vue'
import RoleUpdate from '@/components/roleManager/RoleUpdate.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TrackingFilter from '@/components/trackingUser/TrackingListFilter.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    RoleAddNew,
    RoleUpdate,
    BFormCheckbox,
    TrackingFilter,
  },
  data() {
    return {
      moment,
      allOpenRows: [],
    }
  },
  computed: {
    ...mapGetters('roleManager', ['listRolesOptions']),
  },
  methods: {
    handleViewProducts(item, index) {
      this.allOpenRows.map(ele => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
        }
      })
      this.allOpenRows = []
      this.$set(item, '_showDetails', !item._showDetails)
      this.allOpenRows.push(item)
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    showEditBar(data) {
      store.dispatch('roleManager/fetchRole', { id: data.id })
        .then(res => {
          const resp = res.body
          this.dataRole = {
            name: resp.name,
            permissions: resp.permissions.map(x => ({
              label: this.listRolesOptions.find(e => e.value === x.name).label,
              value: x,
            })),
            id: data.id,
          }
        })
      this.isUpdateRoleSidebarActive = true
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteRate(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this rating.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('roleManager/deleteRole', { id })
              .then(() => {
                this.refreshTable()
              })
          }
        }).catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

  setup() {
    const dataRole = ref({})
    const isAddNewRoleSideBar = ref(false)
    const isUpdateRoleSidebarActive = ref(false)
    const {
      timeout,
      showTable,
      fetchRoleManager,
      tableColumns,
      perPage,
      currentPage,
      totalRoleManager,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleManagerTable,
      refetchData,
      dateFilter,
      exportData,
      dataFieldsDefine,
    } = roleManager()
    return {
      timeout,
      showTable,
      // Sidebar
      dataRole,
      isUpdateRoleSidebarActive,
      isAddNewRoleSideBar,
      fetchRoleManager,
      tableColumns,
      perPage,
      currentPage,
      totalRoleManager,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleManagerTable,
      refetchData,

      // Filter
      avatarText,
      dateFilter,
      exportData,
      dataFieldsDefine,
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
