import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default function roleManager() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refRoleManagerTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'resNo', sortable: false },
    { key: 'surName', sortable: false },
    { key: 'givenName', sortable: false },
    { key: 'room', sortable: false },
    { key: 'arrivalDate', sortable: false },
    { key: 'departureDate', sortable: false },
  ]
  const perPage = ref(10)
  const totalRoleManager = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const dateFilter = ref('')
  const exportData = ref(null)
  const dataFieldsDefine = ref({
    'Res no': 'resCode',
    'Sur name': 'surname',
    'Given name': 'guestGiven',
    Room: 'room',
    'Arrival date': 'arrivalDate',
    'Departure date': 'departureDate',
    Rating: 'rating',
    'Rating title': 'ratingTitle',
  })
  const dataMeta = computed(() => {
    const localItemsCount = refRoleManagerTable.value
      ? refRoleManagerTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoleManager.value,
    }
  })
  const formatDate = time => moment(time).format('HH:mm:ss DD/MM/YYYY')
  const refetchData = () => {
    refRoleManagerTable.value.refresh()
  }
  const fetchRoleManager = (ctx, callback) => {
    const fromDate = dateFilter.value.split('to')[0]
    const toDate = dateFilter.value.split('to')[1]
    store
      .dispatch('statistic/fetchUserRating', {
        q: searchQuery.value === '' ? null : searchQuery.value,
        from: fromDate ? new Date(fromDate) : null,
        to: toDate ? new Date(toDate) : null,
        take: perPage.value,
        page: currentPage.value,
        type: 'login',
      })
      .then(response => {
        const rates = []
        const groupByCustomerId = response.body.reduce((group, product) => {
          const { customerId } = product
          group[customerId] = group[customerId] ?? []
          group[customerId].push(product)
          return group
        }, {})
        Object.keys(groupByCustomerId).forEach(element => {
          const currentCustomer = response.body.find(i => i.customerId === parseInt(element, 10)).customer
          rates.push({
            currentCustomer,
            ratings: groupByCustomerId[element],
          })
        })
        console.log(rates)
        callback(rates)
        exportData.value = response.body.map(x => ({
          resCode: x.customer ? x.customer.resCode : '',
          surname: x.customer ? x.customer.surname : '',
          guestGiven: x.customer ? x.customer.guestGiven : '',
          room: x.customer ? x.customer.room : '',
          arrivalDate: x.customer ? formatDate(x.customer.arrivalDate) : '',
          departureDate: x.customer ? formatDate(x.customer.departureDate) : '',
          rating: x.rating,
          ratingTitle: x.ratingRef.title,
        }))
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching role list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      dateFilter,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    dateFilter,
    timeout,
    showTable,
    fetchRoleManager,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refRoleManagerTable,
    refetchData,
    exportData,
    dataFieldsDefine,
    // Extra
  }
}
